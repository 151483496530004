.layout {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.layout .body {
    flex: 1;
    display: flex;
    flex-direction: column;
}