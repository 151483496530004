* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.75rem;
}

body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  flex: 1;
}

/* Font styles */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
}

p {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 20px;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 300;
}
